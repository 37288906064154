import React, { useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Tag, Info, ExternalLink, Heart, Share2, MessageCircle, ChevronLeft, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { ShareButton } from '../ShareButton';
import { Review } from '../../lib/types';
import { useCategories } from '../../hooks/useCategories';
import { ProductCard } from '../products/ProductCard';
import toast from 'react-hot-toast';

interface ReviewGalleryProps {
  reviews: Review[];
}

export function ReviewGallery({ reviews }: ReviewGalleryProps) {
  const [selectedReview, setSelectedReview] = useState<Review | null>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [activeHighlights, setActiveHighlights] = useState<Set<string>>(new Set());
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const navigate = useNavigate();
  const { categories } = useCategories();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef<HTMLDivElement>(null);

  const handlePrevImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (selectedReview) {
      const totalImages = [selectedReview.image, ...(selectedReview.additional_images || [])];
      setCurrentImageIndex((prev) => 
        prev === 0 ? totalImages.length - 1 : prev - 1
      );
      setImageLoaded(false);
      setActiveHighlights(new Set());
    }
  };

  const handleNextImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (selectedReview) {
      const totalImages = [selectedReview.image, ...(selectedReview.additional_images || [])];
      setCurrentImageIndex((prev) => 
        prev === totalImages.length - 1 ? 0 : prev + 1
      );
      setImageLoaded(false);
      setActiveHighlights(new Set());
    }
  };

  const toggleHighlight = (highlightId: string) => {
    setActiveHighlights(prev => {
      const newSet = new Set(prev);
      if (newSet.has(highlightId)) {
        newSet.delete(highlightId);
      } else {
        newSet.add(highlightId);
      }
      return newSet;
    });
  };

  const handleProductClick = (product: any) => {
    event?.stopPropagation();
    setSelectedProduct(product);
  };

  const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const img = event.currentTarget;
    setImageDimensions({
      width: img.naturalWidth,
      height: img.naturalHeight
    });
    setImageLoaded(true);
  };

  const getCurrentImageHighlights = (review: Review, isModal: boolean = false) => {
    if (!review.product_highlights) return [];
    
    // Get URL of current image
    const currentImageUrl = isModal 
      ? (currentImageIndex === 0 ? review.image : review.additional_images![currentImageIndex - 1])
      : review.image;
    
    // Return only highlights that match the current image URL
    return review.product_highlights.filter(highlight => 
      highlight.image_url === currentImageUrl
    );
  };

  const calculateHighlightPosition = (x: number, y: number) => {
    if (!containerRef.current || !imageLoaded) return { x, y };

    const container = containerRef.current.getBoundingClientRect();
    const imageAspectRatio = imageDimensions.width / imageDimensions.height;
    const containerAspectRatio = container.width / container.height;

    let imageDisplayWidth = container.width;
    let imageDisplayHeight = container.height;

    if (imageAspectRatio > containerAspectRatio) {
      imageDisplayHeight = container.width / imageAspectRatio;
    } else {
      imageDisplayWidth = container.height * imageAspectRatio;
    }

    const offsetX = (container.width - imageDisplayWidth) / 2;
    const offsetY = (container.height - imageDisplayHeight) / 2;

    const realX = (x / 100) * imageDisplayWidth + offsetX;
    const realY = (y / 100) * imageDisplayHeight + offsetY;

    return {
      x: (realX / container.width) * 100,
      y: (realY / container.height) * 100
    };
  };

  // Get unique products for current image
  const getCurrentImageProducts = (review: Review) => {
    const highlights = getCurrentImageHighlights(review, true);
    const uniqueProducts = new Map();
    
    highlights.forEach(highlight => {
      if (highlight.products) {
        uniqueProducts.set(highlight.products.id, highlight.products);
      }
    });
    
    return Array.from(uniqueProducts.values());
  };

  return (
    <div className="space-y-8">
      {/* Grid of Reviews */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {reviews.map((review) => (
          <motion.div
            key={review.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white rounded-2xl shadow-lg overflow-hidden cursor-pointer group"
            onClick={() => {
              setSelectedReview(review);
              setCurrentImageIndex(0);
              setActiveHighlights(new Set());
              setImageLoaded(false);
            }}
          >
            <div className="relative aspect-square overflow-hidden">
              <img
                src={review.image}
                alt={`Отзыв от ${review.customer_name}`}
                className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
              
              {/* Product Highlights - Only show for main image */}
              {getCurrentImageHighlights(review, false).map((highlight, index) => (
                <div
                  key={index}
                  className="absolute w-6 h-6 -ml-3 -mt-3 cursor-pointer"
                  style={{ left: `${highlight.x}%`, top: `${highlight.y}%` }}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleHighlight(highlight.id);
                  }}
                >
                  <div className="w-full h-full bg-white rounded-full shadow-lg flex items-center justify-center">
                    <Tag className="w-3 h-3 text-purple-600" />
                  </div>
                </div>
              ))}

              {/* Review Info Overlay */}
              <div className="absolute inset-x-0 bottom-0 p-4 text-white opacity-0 group-hover:opacity-100 transition-opacity">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="font-medium">{review.customer_name}</p>
                    <p className="text-sm text-gray-300">
                      {new Date(review.created_at).toLocaleDateString('ru-RU')}
                    </p>
                  </div>
                </div>
              </div>

              {/* Multiple Images Indicator */}
              {review.additional_images && review.additional_images.length > 0 && (
                <div className="absolute top-4 right-4 bg-black/50 text-white px-2 py-1 rounded-full text-xs">
                  {review.additional_images.length + 1} фото
                </div>
              )}
            </div>
          </motion.div>
        ))}
      </div>

      {/* Review Detail Modal */}
      <AnimatePresence>
        {selectedReview && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/80"
            onClick={() => {
              setSelectedReview(null);
              setActiveHighlights(new Set());
              setImageLoaded(false);
            }}
          >
            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              onClick={(e) => e.stopPropagation()}
              className="bg-white rounded-2xl overflow-hidden max-w-4xl w-full max-h-[90vh] flex flex-col md:flex-row"
            >
              {/* Image Section */}
              <div ref={containerRef} className="relative w-full md:w-2/3 bg-gray-100">
                <img
                  src={currentImageIndex === 0 ? selectedReview.image : selectedReview.additional_images![currentImageIndex - 1]}
                  alt={`Отзыв от ${selectedReview.customer_name}`}
                  className="w-full h-full object-contain"
                  onLoad={handleImageLoad}
                />

                {/* Product Highlights for Current Image */}
                {imageLoaded && getCurrentImageHighlights(selectedReview, true).map((highlight, index) => {
                  const position = calculateHighlightPosition(highlight.x, highlight.y);
                  
                  return (
                    <div
                      key={index}
                      className="absolute w-8 h-8 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                      style={{ 
                        left: `${position.x}%`, 
                        top: `${position.y}%`
                      }}
                      onClick={() => toggleHighlight(highlight.id)}
                    >
                      <div className="w-full h-full bg-white rounded-full shadow-lg flex items-center justify-center">
                        <Tag className="w-4 h-4 text-purple-600" />
                      </div>
                      
                      {/* Product Info Card - Only show when highlight is active */}
                      {activeHighlights.has(highlight.id) && highlight.products && (
                        <div className="absolute z-10 w-72 bg-white rounded-xl shadow-xl p-4 -translate-x-1/2 mt-2">
                          <button
                            className="flex items-start space-x-4 w-full cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleProductClick(highlight.products);
                            }}
                          >
                            <img
                              src={highlight.products.image}
                              alt={highlight.products.name}
                              className="w-20 h-20 rounded-lg object-cover"
                            />
                            <div className="text-left">
                              <h4 className="font-medium text-gray-900 hover:text-purple-600 transition-colors">
                                {highlight.products.name}
                              </h4>
                              <p className="text-purple-600 font-medium mt-1">
                                {highlight.products.price?.toLocaleString('ru-RU')} ₽
                              </p>
                              <div className="flex items-center text-sm text-gray-500 mt-2 hover:text-purple-600">
                                <ExternalLink className="w-4 h-4 mr-1" />
                                <span>Посмотреть товар</span>
                              </div>
                            </div>
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}

                {/* Navigation Arrows */}
                {selectedReview.additional_images && selectedReview.additional_images.length > 0 && (
                  <>
                    <button
                      onClick={handlePrevImage}
                      className="absolute left-4 top-1/2 transform -translate-y-1/2 p-2 bg-white/10 rounded-full hover:bg-white/20 transition-colors"
                    >
                      <ChevronLeft className="w-6 h-6 text-white" />
                    </button>
                    <button
                      onClick={handleNextImage}
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 p-2 bg-white/10 rounded-full hover:bg-white/20 transition-colors"
                    >
                      <ChevronRight className="w-6 h-6 text-white" />
                    </button>
                  </>
                )}
              </div>

              {/* Info Section */}
              <div className="w-full md:w-1/3 p-6 flex flex-col">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <h3 className="text-xl font-bold text-gray-900">
                      {selectedReview.customer_name}
                    </h3>
                    <p className="text-gray-500">
                      {new Date(selectedReview.created_at).toLocaleDateString('ru-RU')}
                    </p>
                  </div>
                  <button
                    onClick={() => {
                      setSelectedReview(null);
                      setActiveHighlights(new Set());
                    }}
                    className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  >
                    <X className="w-6 h-6 text-gray-500" />
                  </button>
                </div>

                <p className="text-gray-600 flex-grow">
                  {selectedReview.description}
                </p>

                <div className="mt-6 space-y-4">
                  <ShareButton
                    title={`Отзыв от ${selectedReview.customer_name} | РусДекор`}
                    text={selectedReview.description}
                    url={window.location.href}
                  />

                  {/* Show products section only if there are products for current image */}
                  {getCurrentImageProducts(selectedReview).length > 0 && (
                    <div className="bg-purple-50 rounded-xl p-4">
                      <div className="flex items-center space-x-2 text-purple-600 mb-3">
                        <Info className="w-5 h-5" />
                        <span className="font-medium">Использованные товары</span>
                      </div>
                      <div className="space-y-3">
                        {getCurrentImageProducts(selectedReview).map((product) => (
                          <button
                            key={product.id}
                            className="flex items-center space-x-3 p-2 rounded-lg hover:bg-purple-100 transition-colors cursor-pointer w-full"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleProductClick(product);
                            }}
                          >
                            <img
                              src={product.image}
                              alt={product.name}
                              className="w-12 h-12 rounded-lg object-cover"
                            />
                            <div className="text-left">
                              <p className="text-sm font-medium text-gray-900">
                                {product.name}
                              </p>
                              <p className="text-sm text-purple-600">
                                {product.price?.toLocaleString('ru-RU')} ₽
                              </p>
                            </div>
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Product Modal */}
      <AnimatePresence>
        {selectedProduct && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-[60] flex items-center justify-center p-4 bg-black/80"
            onClick={() => setSelectedProduct(null)}
          >
            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              onClick={(e) => e.stopPropagation()}
              className="bg-white rounded-2xl overflow-hidden max-w-lg w-full max-h-[90vh] overflow-y-auto"
            >
              <div className="p-6">
                <div className="flex justify-end mb-4">
                  <button
                    onClick={() => setSelectedProduct(null)}
                    className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  >
                    <X className="w-6 h-6 text-gray-500" />
                  </button>
                </div>
                <ProductCard {...selectedProduct} />
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
