import React, { useState } from 'react';
import { Menu, X, Home, Info, Phone, Tag, FileText, ShoppingBag, Settings, BarChart3, Calculator, Clock, Instagram, Send, ChevronDown, Camera, Search, X as XIcon } from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useSettings } from '../../hooks/useSettings';
import { useCategories } from '../../hooks/useCategories';
import { useAuth } from '../../context/AuthContext';
import { useProducts } from '../../hooks/useProducts';

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHoveringCatalog, setIsHoveringCatalog] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<Array<{ id: string; name: string; category_id: string; image: string; price: number; description: string }>>([]);
  const { settings } = useSettings();
  const location = useLocation();
  const navigate = useNavigate();
  const { categories } = useCategories();
  const { user } = useAuth();
  const { products } = useProducts();

  const isActive = (path: string) => location.pathname === path;

  const menuItems = [
    { path: '/', icon: Home, label: 'Главная' },
    { path: '/catalog', icon: ShoppingBag, label: 'Каталог' },
    { path: '/sale', icon: Tag, label: 'Акции' },
    { path: '/blog', icon: FileText, label: 'Блог' },
    { path: '/reviews', icon: Camera, label: 'Отзывы' },
    { path: '/about', icon: Info, label: 'О нас' },
    { path: '/contact', icon: Phone, label: 'Контакты' },
    { path: '/calculators', icon: Calculator, label: 'Калькуляторы' },
  ];

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    if (query.trim().length >= 2) {
      const results = products.filter(product =>
        product.name.toLowerCase().includes(query.toLowerCase()) ||
        product.description.toLowerCase().includes(query.toLowerCase())
      ).slice(0, 5); // Limit to 5 results for better UX
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  const handleSearchSelect = (categoryId: string) => {
    const category = categories.find(c => c.id === categoryId);
    if (category) {
      navigate(`/category/${category.slug}?search=${encodeURIComponent(searchQuery)}`);
      setIsSearchOpen(false);
      setSearchQuery('');
      setSearchResults([]);
    }
  };

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('ru-RU', {
      style: 'currency',
      currency: 'RUB',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(price);
  };

  return (
    <header className="fixed top-0 left-0 right-0 z-50">
      {/* Top Bar - Desktop */}
      <div className="hidden md:block bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="h-10 flex items-center justify-between">
            <div className="flex-1" />
            <div className="flex items-center space-x-6 justify-center flex-1">
              {settings.whatsapp_number && (
                <a href={`tel:${settings.whatsapp_number}`} className="flex items-center text-gray-600 hover:text-amber-600 transition-colors">
                  <Phone className="w-4 h-4 mr-2" />
                  <span>{settings.whatsapp_number}</span>
                </a>
              )}
              {settings.working_hours && (
                <div className="flex items-center text-gray-600">
                  <Clock className="w-4 h-4 mr-2" />
                  <span>{settings.working_hours}</span>
                </div>
              )}
            </div>
            <div className="flex items-center space-x-4 flex-1 justify-end">
              {settings.instagram_url && (
                <a href={settings.instagram_url} target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-amber-600 transition-colors">
                  <Instagram className="w-5 h-5" />
                </a>
              )}
              {settings.telegram_url && (
                <a href={settings.telegram_url} target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-amber-600 transition-colors">
                  <Send className="w-5 h-5" />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Main Header */}
      <div className="bg-white shadow-lg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="h-24 flex items-center justify-between">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden p-2 rounded-lg text-gray-700 hover:bg-amber-50 hover:text-amber-600"
            >
              {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
            <div className="flex-1 flex justify-center">
              <Link to="/" className="flex-shrink-0">
                <img
                  src={settings.logo_url}
                  alt={settings.site_title}
                  className="h-16 md:h-20 w-auto"
                  width={180}
                  height={72}
                  loading="eager"
                />
              </Link>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => setIsSearchOpen(!isSearchOpen)}
                className="p-2 rounded-lg text-gray-700 hover:bg-amber-50 hover:text-amber-600"
              >
                <Search className="h-6 w-6" />
              </button>
            </div>
          </div>

          {/* Enhanced Search Overlay */}
          <AnimatePresence>
            {isSearchOpen && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="absolute left-0 right-0 top-full bg-white shadow-xl border-t z-50"
              >
                <div className="max-w-3xl mx-auto p-4">
                  <div className="relative">
                    <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => handleSearch(e.target.value)}
                      placeholder="Поиск товаров..."
                      className="w-full pl-12 pr-12 py-3 border border-gray-300 rounded-xl focus:ring-2 focus:ring-amber-500 focus:border-transparent"
                    />
                    {searchQuery && (
                      <button
                        onClick={() => {
                          setSearchQuery('');
                          setSearchResults([]);
                        }}
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                      >
                        <XIcon className="w-5 h-5" />
                      </button>
                    )}
                  </div>

                  {searchResults.length > 0 && (
                    <div className="mt-4 bg-white rounded-xl shadow-lg border max-h-[calc(100vh-200px)] overflow-y-auto">
                      {searchResults.map((result) => {
                        const category = categories.find(c => c.id === result.category_id);
                        return (
                          <button
                            key={result.id}
                            onClick={() => handleSearchSelect(result.category_id)}
                            className="w-full p-4 hover:bg-gray-50 flex items-center gap-4 border-b last:border-b-0 transition-colors"
                          >
                            <div className="w-16 h-16 flex-shrink-0">
                              <img
                                src={result.image}
                                alt={result.name}
                                className="w-full h-full object-cover rounded-lg"
                                loading="lazy"
                              />
                            </div>
                            <div className="flex-1 text-left">
                              <div className="font-medium text-gray-900">{result.name}</div>
                              <div className="text-sm text-gray-500 line-clamp-1">{result.description}</div>
                              <div className="mt-1 flex items-center justify-between">
                                <span className="text-amber-600 font-semibold">
                                  {formatPrice(result.price)}
                                </span>
                                {category && (
                                  <span className="text-xs text-gray-500 bg-gray-100 px-2 py-1 rounded-full">
                                    {category.name}
                                  </span>
                                )}
                              </div>
                            </div>
                          </button>
                        );
                      })}
                    </div>
                  )}

                  {searchQuery && searchResults.length === 0 && (
                    <div className="mt-4 text-center text-gray-500 py-8">
                      По запросу "{searchQuery}" ничего не найдено
                    </div>
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Navigation Menu - Desktop */}
          <nav className="hidden md:flex items-center justify-center h-12 border-t">
            {menuItems.map((item) => (
              item.path === '/catalog' ? (
                <div
                  key={item.path}
                  className="relative"
                  onMouseEnter={() => setIsHoveringCatalog(true)}
                  onMouseLeave={() => setIsHoveringCatalog(false)}
                >
                  <Link
                    to="/catalog"
                    className={`flex items-center space-x-2 px-3 py-2 rounded-lg transition-all duration-300 ${
                      isActive('/catalog') || isHoveringCatalog
                        ? 'text-amber-600 bg-amber-50'
                        : 'text-gray-700 hover:text-amber-600 hover:bg-amber-50'
                    }`}
                    aria-expanded={isHoveringCatalog}
                    aria-haspopup="true"
                  >
                    <ShoppingBag className="w-5 h-5" aria-hidden="true" />
                    <span className="font-medium">Каталог</span>
                    <ChevronDown
                      className={`w-4 h-4 transition-transform duration-300 ${isHoveringCatalog ? 'rotate-180' : ''}`}
                      aria-hidden="true"
                    />
                  </Link>

                  <AnimatePresence>
                    {isHoveringCatalog && (
                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        className="absolute top-full left-0 mt-2 w-[400px] bg-white rounded-xl shadow-xl p-4"
                        role="menu"
                      >
                        <div className="grid grid-cols-2 gap-2">
                          {categories.filter(c => !c.parent_id).map((category) => (
                            <Link
                              key={category.id}
                              to={`/category/${category.slug}`}
                              className="group flex items-center p-2 rounded-lg hover:bg-amber-50 transition-all duration-300"
                              onClick={() => setIsHoveringCatalog(false)}
                              role="menuitem"
                            >
                              <img
                                src={category.image}
                                alt=""
                                className="w-10 h-10 rounded-lg object-cover"
                                width={40}
                                height={40}
                                loading="lazy"
                                aria-hidden="true"
                              />
                              <span className="ml-3 text-sm font-medium text-gray-700 group-hover:text-amber-600">
                                {category.name}
                              </span>
                            </Link>
                          ))}
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ) : (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-all duration-300 ${
                    isActive(item.path)
                      ? 'text-amber-600 bg-amber-50'
                      : 'text-gray-700 hover:text-amber-600 hover:bg-amber-50'
                  }`}
                >
                  <item.icon className="w-5 h-5" />
                  <span className="font-medium">{item.label}</span>
                </Link>
              )
            ))}
          </nav>
        </div>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="md:hidden bg-white border-t"
          >
            <div className="px-4 py-3 border-b space-y-2">
              {settings.whatsapp_number && (
                <a href={`tel:${settings.whatsapp_number}`} className="flex items-center text-gray-600">
                  <Phone className="w-4 h-4 mr-2" />
                  <span>{settings.whatsapp_number}</span>
                </a>
              )}
              {settings.working_hours && (
                <div className="flex items-center text-gray-600">
                  <Clock className="w-4 h-4 mr-2" />
                  <span>{settings.working_hours}</span>
                </div>
              )}
            </div>
            <nav className="grid gap-2 p-4">
              {menuItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  onClick={() => setIsMenuOpen(false)}
                  className={`flex items-center space-x-3 p-3 rounded-lg ${
                    isActive(item.path)
                      ? 'bg-amber-50 text-amber-600'
                      : 'text-gray-700 hover:bg-amber-50 hover:text-amber-600'
                  }`}
                >
                  <item.icon className="w-5 h-5" />
                  <span className="font-medium">{item.label}</span>
                </Link>
              ))}
            </nav>

            {/* Social Links - Mobile */}
            <div className="px-4 py-3 border-t flex justify-center space-x-6">
              {settings.instagram_url && (
                <a href={settings.instagram_url} target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-amber-600">
                  <Instagram className="w-6 h-6" />
                </a>
              )}
              {settings.telegram_url && (
                <a href={settings.telegram_url} target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-amber-600">
                  <Send className="w-6 h-6" />
                </a>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
}
