import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Camera, Upload, Info } from 'lucide-react';
import { ReviewGallery } from '../components/reviews/ReviewGallery';
import { ReviewForm } from '../components/reviews/ReviewForm';
import { SEO } from '../components/SEO';
import { reviewService } from '../lib/services/reviewService';
import { Review } from '../lib/types';
import toast from 'react-hot-toast';

export default function ReviewsPage() {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadReviews();
  }, []);

  const loadReviews = async () => {
    try {
      setLoading(true);
      const data = await reviewService.getApproved();
      setReviews(data);
    } catch (error) {
      console.error('Error loading reviews:', error);
      toast.error('Не удалось загрузить отзывы');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitReview = async (data: any) => {
    try {
      await reviewService.create(data);
      toast.success('Спасибо за ваш отзыв! После модерации он появится на сайте. Скидка 10% будет применена к следующему заказу.');
      setIsFormOpen(false);
      // Refresh reviews after submission
      loadReviews();
    } catch (error) {
      console.error('Error submitting review:', error);
      toast.error('Не удалось отправить отзыв. Пожалуйста, попробуйте позже.');
    }
  };

  return (
    <>
      <SEO 
        title="Отзывы наших клиентов | РусДекор"
        description="Галерея фотографий интерьеров наших клиентов с использованием декоративных элементов от РусДекор"
      />

      <div className="min-h-screen bg-gradient-to-br from-purple-50 via-white to-pink-50 pt-24 pb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-12"
          >
            <div className="flex items-center justify-center mb-4">
              <Camera className="w-12 h-12 text-purple-600" />
            </div>
            <h1 className="text-4xl font-bold text-gray-900 mb-4">Галерея отзывов</h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Вдохновляйтесь реальными проектами наших клиентов и узнайте, какие товары они использовали
            </p>
          </motion.div>

          {/* Upload CTA with Discount Info */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white rounded-2xl shadow-lg p-8 mb-12"
          >
            <div className="flex flex-col md:flex-row items-center justify-between gap-6">
              <div className="flex-1">
                <div className="flex items-center space-x-3 mb-2">
                  <h2 className="text-2xl font-bold text-gray-900">
                    Поделитесь своим результатом!
                  </h2>
                  <span className="bg-red-100 text-red-600 px-3 py-1 rounded-full text-sm font-semibold">
                    -10% скидка
                  </span>
                </div>
                <p className="text-gray-600">
                  Загрузите фото вашего интерьера с использованием наших товаров и получите скидку 10% 
                  на следующую покупку. Ваш опыт поможет другим клиентам сделать правильный выбор!
                </p>
              </div>
              <button
                onClick={() => setIsFormOpen(true)}
                className="flex items-center px-6 py-3 bg-purple-600 text-white rounded-xl font-medium hover:bg-purple-700 transition-colors"
              >
                <Upload className="w-5 h-5 mr-2" />
                <span>Оставить отзыв</span>
              </button>
            </div>
          </motion.div>

          {/* Tips */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12"
          >
            <div className="bg-white rounded-xl p-6 shadow-lg">
              <div className="w-12 h-12 bg-purple-100 rounded-xl flex items-center justify-center mb-4">
                <Camera className="w-6 h-6 text-purple-600" />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                Качественные фото
              </h3>
              <p className="text-gray-600">
                Делайте фотографии при хорошем освещении, чтобы показать все детали интерьера
              </p>
            </div>

            <div className="bg-white rounded-xl p-6 shadow-lg">
              <div className="w-12 h-12 bg-purple-100 rounded-xl flex items-center justify-center mb-4">
                <Upload className="w-6 h-6 text-purple-600" />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                Отметьте товары
              </h3>
              <p className="text-gray-600">
                Укажите на фото использованные товары, чтобы помочь другим с выбором
              </p>
            </div>

            <div className="bg-white rounded-xl p-6 shadow-lg">
              <div className="w-12 h-12 bg-purple-100 rounded-xl flex items-center justify-center mb-4">
                <Info className="w-6 h-6 text-purple-600" />
              </div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                Добавьте описание
              </h3>
              <p className="text-gray-600">
                Расскажите о своем опыте использования товаров и результате ремонта
              </p>
            </div>
          </motion.div>

          {/* Reviews Gallery */}
          {loading ? (
            <div className="text-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600 mx-auto"></div>
              <p className="mt-4 text-gray-600">Загрузка отзывов...</p>
            </div>
          ) : reviews.length === 0 ? (
            <div className="text-center py-12">
              <p className="text-gray-600">Пока нет отзывов. Станьте первым, кто поделится своим опытом!</p>
            </div>
          ) : (
            <ReviewGallery reviews={reviews} />
          )}

          {/* Review Form Modal */}
          <ReviewForm
            isOpen={isFormOpen}
            onClose={() => setIsFormOpen(false)}
            onSubmit={handleSubmitReview}
          />
        </div>
      </div>
    </>
  );
}
