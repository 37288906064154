import { supabase } from '../supabase';
import { imageService } from './imageService';
import { Review, ReviewInput, ProductHighlight } from '../types';

export const reviewService = {
  async getAll(): Promise<Review[]> {
    const { data, error } = await supabase
      .from('reviews')
      .select(`
        *,
        product_highlights (
          id,
          x,
          y,
          image_url,
          product_id,
          products (
            id,
            name,
            price,
            image
          )
        )
      `)
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data || [];
  },

  async getApproved(): Promise<Review[]> {
    const { data, error } = await supabase
      .from('reviews')
      .select(`
        *,
        product_highlights (
          id,
          x,
          y,
          image_url,
          product_id,
          products (
            id,
            name,
            price,
            image
          )
        )
      `)
      .eq('status', 'approved')
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data || [];
  },

  async uploadImage(file: File): Promise<string> {
    return imageService.upload(file);
  },

  async create(review: ReviewInput): Promise<Review> {
    try {
      // Create the review
      const { data: reviewData, error: reviewError } = await supabase
        .from('reviews')
        .insert([{
          customer_name: review.customerName,
          description: review.description,
          image: review.image,
          additional_images: review.additional_images,
          status: 'pending', // All new reviews start as pending
        }])
        .select()
        .single();

      if (reviewError) throw reviewError;

      // Add product highlights with image URLs
      if (review.productHighlights.length > 0) {
        const { error: highlightsError } = await supabase
          .from('product_highlights')
          .insert(
            review.productHighlights.map(highlight => ({
              review_id: reviewData.id,
              product_id: highlight.productId,
              x: highlight.x,
              y: highlight.y,
              image_url: highlight.imageUrl // Добавляем URL изображения
            }))
          );

        if (highlightsError) throw highlightsError;
      }

      return reviewData;
    } catch (error) {
      console.error('Error creating review:', error);
      throw error;
    }
  },

  async updateStatus(id: string, status: 'pending' | 'approved' | 'rejected'): Promise<void> {
    const { error } = await supabase
      .from('reviews')
      .update({ status })
      .eq('id', id);

    if (error) throw error;
  },

  async delete(id: string): Promise<void> {
    const { error } = await supabase
      .from('reviews')
      .delete()
      .eq('id', id);

    if (error) throw error;
  }
};
