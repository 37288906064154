import React, { useState, useEffect } from 'react';
import { Layout } from '../../components/admin/Layout';
import { reviewService } from '../../lib/services/reviewService';
import { 
  Camera, 
  CheckCircle, 
  XCircle, 
  Clock, 
  Tag,
  Search,
  ChevronLeft,
  ChevronRight,
  Eye,
  Trash2
} from 'lucide-react';
import { reviewService } from '../../lib/services/reviewService';
import { Review } from '../../lib/types';
import { motion, AnimatePresence } from 'framer-motion';
import toast from 'react-hot-toast';

const ITEMS_PER_PAGE = 10;

export function AdminReviewsPage() {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedReview, setSelectedReview] = useState<Review | null>(null);
  const [filter, setFilter] = useState<'all' | 'pending' | 'approved' | 'rejected'>('all');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    loadReviews();
  }, []);

  const loadReviews = async () => {
    try {
      setLoading(true);
      const data = await reviewService.getAll();
      setReviews(data);
    } catch (error) {
      toast.error('Ошибка при загрузке отзывов');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (id: string, status: 'pending' | 'approved' | 'rejected') => {
    try {
      await reviewService.updateStatus(id, status);
      setReviews(prev => prev.map(review => 
        review.id === id ? { ...review, status } : review
      ));
      toast.success('Статус отзыва обновлен');
    } catch (error) {
      toast.error('Ошибка при обновлении статуса');
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm('Вы уверены, что хотите удалить этот отзыв?')) return;
    
    try {
      await reviewService.delete(id);
      setReviews(prev => prev.filter(review => review.id !== id));
      toast.success('Отзыв удален');
    } catch (error) {
      toast.error('Ошибка при удалении отзыва');
    }
  };

  const filteredReviews = reviews.filter(review => {
    const matchesFilter = filter === 'all' || review.status === filter;
    const matchesSearch = searchTerm === '' || 
      review.customer_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      review.description.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesFilter && matchesSearch;
  });

  const totalPages = Math.ceil(filteredReviews.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentReviews = filteredReviews.slice(startIndex, endIndex);

  return (
    <Layout>
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center space-x-3">
            <Camera className="w-8 h-8 text-purple-600" />
            <h1 className="text-2xl font-bold text-gray-900">Управление отзывами</h1>
          </div>
          <div className="flex items-center space-x-4">
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value as any)}
              className="rounded-lg border border-gray-300 px-3 py-2 focus:ring-2 focus:ring-purple-500"
            >
              <option value="all">Все отзывы</option>
              <option value="pending">На модерации</option>
              <option value="approved">Одобренные</option>
              <option value="rejected">Отклоненные</option>
            </select>
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <input
                type="text"
                placeholder="Поиск отзывов..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500"
              />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-sm overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Фото
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Клиент
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Отзыв
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Статус
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Дата
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Действия
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {loading ? (
                  <tr>
                    <td colSpan={6} className="px-6 py-4 text-center text-gray-500">
                      Загрузка...
                    </td>
                  </tr>
                ) : currentReviews.length === 0 ? (
                  <tr>
                    <td colSpan={6} className="px-6 py-4 text-center text-gray-500">
                      Отзывы не найдены
                    </td>
                  </tr>
                ) : (
                  currentReviews.map((review) => (
                    <tr key={review.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <img
                            src={review.image}
                            alt=""
                            className="h-16 w-16 rounded-lg object-cover cursor-pointer"
                            onClick={() => setSelectedReview(review)}
                          />
                          {review.additional_images && review.additional_images.length > 0 && (
                            <span className="ml-2 text-xs text-gray-500">
                              +{review.additional_images.length}
                            </span>
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {review.customer_name}
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-gray-500 line-clamp-2">
                          {review.description}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          review.status === 'approved' ? 'bg-green-100 text-green-800' :
                          review.status === 'rejected' ? 'bg-red-100 text-red-800' :
                          'bg-yellow-100 text-yellow-800'
                        }`}>
                          {review.status === 'approved' ? 'Одобрен' :
                           review.status === 'rejected' ? 'Отклонен' :
                           'На модерации'}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(review.created_at).toLocaleDateString('ru-RU')}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button
                          onClick={() => setSelectedReview(review)}
                          className="text-purple-600 hover:text-purple-900 mr-3"
                          title="Просмотреть"
                        >
                          <Eye className="w-5 h-5" />
                        </button>
                        {review.status === 'pending' && (
                          <>
                            <button
                              onClick={() => handleStatusChange(review.id, 'approved')}
                              className="text-green-600 hover:text-green-900 mr-3"
                              title="Одобрить"
                            >
                              <CheckCircle className="w-5 h-5" />
                            </button>
                            <button
                              onClick={() => handleStatusChange(review.id, 'rejected')}
                              className="text-red-600 hover:text-red-900 mr-3"
                              title="Отклонить"
                            >
                              <XCircle className="w-5 h-5" />
                            </button>
                          </>
                        )}
                        <button
                          onClick={() => handleDelete(review.id)}
                          className="text-red-600 hover:text-red-900"
                          title="Удалить"
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          {totalPages > 1 && (
            <div className="flex items-center justify-between px-6 py-4 bg-white border-t">
              <div className="flex items-center">
                <p className="text-sm text-gray-700">
                  Показано {startIndex + 1}-{Math.min(endIndex, filteredReviews.length)} из {filteredReviews.length}
                </p>
              </div>
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                  disabled={currentPage === 1}
                  className="p-2 rounded-lg border border-gray-300 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
                >
                  <ChevronLeft className="w-5 h-5" />
                </button>
                {[...Array(totalPages)].map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentPage(index + 1)}
                    className={`w-8 h-8 rounded-lg border ${
                      currentPage === index + 1
                        ? 'bg-purple-600 text-white border-purple-600'
                        : 'border-gray-300 hover:bg-gray-50'
                    }`}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                  disabled={currentPage === totalPages}
                  className="p-2 rounded-lg border border-gray-300 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
                >
                  <ChevronRight className="w-5 h-5" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Review Detail Modal */}
      <AnimatePresence>
        {selectedReview && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/80"
            onClick={() => setSelectedReview(null)}
          >
            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              onClick={(e) => e.stopPropagation()}
              className="bg-white rounded-2xl max-w-4xl w-full max-h-[90vh] overflow-y-auto"
            >
              <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                  <div>
                    <h2 className="text-2xl font-bold text-gray-900">
                      Отзыв от {selectedReview.customer_name}
                    </h2>
                    <p className="text-gray-500">
                      {new Date(selectedReview.created_at).toLocaleDateString('ru-RU')}
                    </p>
                  </div>
                  <button
                    onClick={() => setSelectedReview(null)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <XCircle className="w-6 h-6" />
                  </button>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <div className="aspect-square rounded-xl overflow-hidden mb-4">
                      <img
                        src={selectedReview.image}
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                    {selectedReview.additional_images && selectedReview.additional_images.length > 0 && (
                      <div className="grid grid-cols-4 gap-2">
                        {selectedReview.additional_images.map((image, index) => (
                          <img
                            key={index}
                            src={image}
                            alt=""
                            className="aspect-square rounded-lg object-cover"
                          />
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="space-y-6">
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900 mb-2">Текст отзыва</h3>
                      <p className="text-gray-600">{selectedReview.description}</p>
                    </div>

                    {selectedReview.product_highlights && selectedReview.product_highlights.length > 0 && (
                      <div>
                        <h3 className="text-lg font-semibold text-gray-900 mb-2">
                          Отмеченные товары
                        </h3>
                        <div className="space-y-3">
                          {selectedReview.product_highlights.map((highlight) => (
                            <div
                              key={highlight.id}
                              className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg"
                            >
                              <img
                                src={highlight.products?.image}
                                alt=""
                                className="w-12 h-12 rounded-lg object-cover"
                              />
                              <div>
                                <p className="font-medium text-gray-900">
                                  {highlight.products?.name}
                                </p>
                                <p className="text-sm text-gray-500">
                                  {highlight.products?.price.toLocaleString('ru-RU')} ₽
                                </p>
                              </div>
                              <div className="text-xs text-gray-400 ml-auto">
                                {highlight.x.toFixed(0)}%, {highlight.y.toFixed(0)}%
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    <div className="flex space-x-3">
                      {selectedReview.status === 'pending' && (
                        <>
                          <button
                            onClick={() => {
                              handleStatusChange(selectedReview.id, 'approved');
                              setSelectedReview(null);
                            }}
                            className="flex-1 bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition-colors"
                          >
                            Одобрить
                          </button>
                          <button
                            onClick={() => {
                              handleStatusChange(selectedReview.id, 'rejected');
                              setSelectedReview(null);
                            }}
                            className="flex-1 bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition-colors"
                          >
                            Отклонить
                          </button>
                        </>
                      )}
                      <button
                        onClick={() => {
                          handleDelete(selectedReview.id);
                          setSelectedReview(null);
                        }}
                        className="flex-1 bg-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition-colors"
                      >
                        Удалить
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </Layout>
  );
}
