import React, { useState, useRef, useEffect } from 'react';
import { Camera, Upload, Star, X, Tag, Plus, Loader2 } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useProducts } from '../../hooks/useProducts';
import { Product } from '../../types';
import { reviewService } from '../../lib/services/reviewService';
import toast from 'react-hot-toast';

interface ReviewFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: ReviewFormData) => void;
}

interface ReviewFormData {
  customerName: string;
  description: string;
  images: File[];
  productHighlights: {
    imageUrl: string;
    productId: string;
    x: number;
    y: number;
  }[];
}

interface ProductHighlight {
  imageUrl: string;
  productId: string;
  x: number;
  y: number;
}

export function ReviewForm({ isOpen, onClose, onSubmit }: ReviewFormProps) {
  const [formData, setFormData] = useState<ReviewFormData>({
    customerName: '',
    description: '',
    images: [],
    productHighlights: []
  });
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [tempHighlight, setTempHighlight] = useState<{ x: number; y: number } | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const { products } = useProducts();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    
    if (showSuccessModal) {
      setCountdown(5);
      timer = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            setShowSuccessModal(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [showSuccessModal]);

  const handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    if (files.length > 0) {
      if (files.length + formData.images.length > 5) {
        toast.error('Максимум 5 фотографий');
        return;
      }

      setFormData(prev => ({
        ...prev,
        images: [...prev.images, ...files]
      }));

      // Create preview URLs
      const newPreviewUrls = files.map(file => URL.createObjectURL(file));
      setPreviewUrls(prev => [...prev, ...newPreviewUrls]);
    }
  };

  const removeImage = (index: number) => {
    setFormData(prev => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index),
      // Remove highlights for the deleted image
      productHighlights: prev.productHighlights.filter(h => h.imageUrl !== previewUrls[index])
    }));
    
    URL.revokeObjectURL(previewUrls[index]);
    setPreviewUrls(prev => prev.filter((_, i) => i !== index));
    
    if (currentImageIndex >= previewUrls.length - 1) {
      setCurrentImageIndex(Math.max(0, previewUrls.length - 2));
    }
  };

  const handleImageClick = (event: React.MouseEvent<HTMLImageElement>) => {
    if (!isAddingProduct || !imageRef.current) return;

    const rect = imageRef.current.getBoundingClientRect();
    const x = ((event.clientX - rect.left) / rect.width) * 100;
    const y = ((event.clientY - rect.top) / rect.height) * 100;

    setTempHighlight({ x, y });
    setIsAddingProduct(true);
  };

  const addProductHighlight = (product: Product) => {
    if (!tempHighlight) return;

    const currentImageUrl = previewUrls[currentImageIndex];

    setFormData(prev => ({
      ...prev,
      productHighlights: [
        ...prev.productHighlights,
        {
          imageUrl: currentImageUrl,
          productId: product.id,
          x: tempHighlight.x,
          y: tempHighlight.y
        }
      ]
    }));

    setTempHighlight(null);
    setIsAddingProduct(false);
    setSelectedProduct(null);
    setSearchTerm('');
  };

  const removeProductHighlight = (highlightIndex: number) => {
    setFormData(prev => ({
      ...prev,
      productHighlights: prev.productHighlights.filter((_, i) => i !== highlightIndex)
    }));
  };

  const getCurrentImageHighlights = () => {
    const currentImageUrl = previewUrls[currentImageIndex];
    return formData.productHighlights.filter(h => h.imageUrl === currentImageUrl);
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.images.length === 0) {
      toast.error('Добавьте хотя бы одну фотографию');
      return;
    }

    try {
      setIsSubmitting(true);
      const uploadedImages = await Promise.all(formData.images.map(async (file) => {
        const url = await reviewService.uploadImage(file);
        return url;
      }));

      const updatedHighlights = formData.productHighlights.map(highlight => {
        const previewIndex = previewUrls.indexOf(highlight.imageUrl);
        return {
          ...highlight,
          imageUrl: uploadedImages[previewIndex]
        };
      });

      const reviewData = {
        customerName: formData.customerName,
        description: formData.description,
        image: uploadedImages[0],
        additional_images: uploadedImages.slice(1),
        productHighlights: updatedHighlights
      };

      await reviewService.create(reviewData);
      
      // Reset form data
      setFormData({
        customerName: '',
        description: '',
        images: [],
        productHighlights: []
      });
      setPreviewUrls([]);
      setCurrentImageIndex(0);
      
      // Close the form and show success modal
      onClose();
      setShowSuccessModal(true);
    } catch (error) {
      toast.error('Ошибка при отправке отзыва');
      console.error('Error submitting review:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const handleSuccessModalClose = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event bubbling
    setShowSuccessModal(false);
  };

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/80"
            onClick={onClose}
          >
            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              onClick={(e) => e.stopPropagation()}
              className="bg-white rounded-2xl max-w-4xl w-full max-h-[90vh] overflow-y-auto"
            >
              <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-2xl font-bold text-gray-900">Оставить отзыв</h2>
                  <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                    <X className="w-6 h-6" />
                  </button>
                </div>

                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Ваше имя
                    </label>
                    <input
                      type="text"
                      value={formData.customerName}
                      onChange={e => setFormData(prev => ({ ...prev, customerName: e.target.value }))}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Ваш отзыв
                    </label>
                    <textarea
                      value={formData.description}
                      onChange={e => setFormData(prev => ({ ...prev, description: e.target.value }))}
                      rows={4}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Фотографии
                    </label>
                    <div className="grid grid-cols-5 gap-4">
                      {previewUrls.map((url, index) => (
                        <div
                          key={index}
                          className={`relative aspect-square rounded-lg overflow-hidden ${
                            index === currentImageIndex ? 'ring-2 ring-purple-500' : ''
                          }`}
                          onClick={() => setCurrentImageIndex(index)}
                        >
                          <img
                            src={url}
                            alt={`Preview ${index + 1}`}
                            className="w-full h-full object-cover"
                          />
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              removeImage(index);
                            }}
                            className="absolute top-2 right-2 p-1 bg-white rounded-full shadow-md hover:bg-gray-100"
                          >
                            <X className="w-4 h-4" />
                          </button>
                          {formData.productHighlights.filter(h => h.imageUrl === url).length > 0 && (
                            <div className="absolute bottom-2 right-2 bg-purple-600 text-white px-2 py-1 rounded-full text-xs font-medium">
                              {formData.productHighlights.filter(h => h.imageUrl === url).length}
                            </div>
                          )}
                        </div>
                      ))}
                      {previewUrls.length < 5 && (
                        <button
                          type="button"
                          onClick={() => fileInputRef.current?.click()}
                          className="aspect-square rounded-lg border-2 border-dashed border-gray-300 flex flex-col items-center justify-center hover:border-purple-500 transition-colors"
                        >
                          <Camera className="w-8 h-8 text-gray-400" />
                          <span className="text-sm text-gray-500 mt-2">Добавить фото</span>
                        </button>
                      )}
                    </div>

                    <input
                      ref={fileInputRef}
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={handleImageSelect}
                      className="hidden"
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      Можно загрузить до 5 фотографий
                    </p>
                  </div>

                  {previewUrls.length > 0 && (
                    <div>
                      <div className="flex items-center justify-between mb-4">
                        <label className="block text-sm font-medium text-gray-700">
                          Отметить товары на фото {currentImageIndex + 1}
                        </label>
                        <button
                          type="button"
                          onClick={() => setIsAddingProduct(!isAddingProduct)}
                          className={`flex items-center px-3 py-1.5 rounded-lg text-sm ${
                            isAddingProduct
                              ? 'bg-purple-100 text-purple-700'
                              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                          }`}
                        >
                          <Tag className="w-4 h-4 mr-1.5" />
                          {isAddingProduct ? 'Отменить' : 'Отметить товар'}
                        </button>
                      </div>

                      <div className="relative">
                        <img
                          ref={imageRef}
                          src={previewUrls[currentImageIndex]}
                          alt="Current preview"
                          className={`w-full rounded-lg ${
                            isAddingProduct ? 'cursor-crosshair' : 'cursor-default'
                          }`}
                          onClick={handleImageClick}
                        />

                        {getCurrentImageHighlights().map((highlight, index) => {
                          const product = products.find(p => p.id === highlight.productId);
                          if (!product) return null;

                          return (
                            <div
                              key={index}
                              className="absolute w-8 h-8 -ml-4 -mt-4"
                              style={{ left: `${highlight.x}%`, top: `${highlight.y}%` }}
                            >
                              <div className="w-full h-full bg-white rounded-full shadow-lg flex items-center justify-center">
                                <Tag className="w-4 h-4 text-purple-600" />
                              </div>
                              <div className="absolute left-full ml-2 top-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg p-2 min-w-[200px]">
                                <div className="flex items-center">
                                  <img
                                    src={product.image}
                                    alt={product.name}
                                    className="w-10 h-10 rounded object-cover"
                                  />
                                  <div className="ml-2 flex-1">
                                    <div className="text-sm font-medium text-gray-900">
                                      {product.name}
                                    </div>
                                    <div className="text-sm text-gray-500">
                                      {product.price.toLocaleString('ru-RU')} ₽
                                    </div>
                                  </div>
                                  <button
                                    type="button"
                                    onClick={() => removeProductHighlight(index)}
                                    className="p-1 hover:bg-gray-100 rounded-full"
                                  >
                                    <X className="w-4 h-4 text-gray-400" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        {tempHighlight && (
                          <div
                            className="absolute w-8 h-8 -ml-4 -mt-4"
                            style={{ left: `${tempHighlight.x}%`, top: `${tempHighlight.y}%` }}
                          >
                            <div className="w-full h-full bg-white rounded-full shadow-lg flex items-center justify-center animate-pulse">
                              <Plus className="w-4 h-4 text-purple-600" />
                            </div>
                            <div className="absolute left-full ml-2 top-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg p-4 min-w-[300px]">
                              <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                  Выберите товар
                                </label>
                                <input
                                  type="text"
                                  value={searchTerm}
                                  onChange={(e) => setSearchTerm(e.target.value)}
                                  placeholder="Поиск товара..."
                                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500"
                                />
                              </div>
                              <div className="max-h-60 overflow-y-auto">
                                {filteredProducts.map((product) => (
                                  <button
                                    key={product.id}
                                    type="button"
                                    onClick={() => addProductHighlight(product)}
                                    className="w-full flex items-center p-2 hover:bg-gray-50 rounded-lg"
                                  >
                                    <img
                                      src={product.image}
                                      alt={product.name}
                                      className="w-10 h-10 rounded object-cover"
                                    />
                                    <div className="ml-2 text-left">
                                      <div className="text-sm font-medium text-gray-900">
                                        {product.name}
                                      </div>
                                      <div className="text-sm text-gray-500">
                                        {product.price.toLocaleString('ru-RU')} ₽
                                      </div>
                                    </div>
                                  </button>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="flex justify-end space-x-3">
                    <button
                      type="button"
                      onClick={onClose}
                      className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
                      disabled={isSubmitting}
                    >
                      Отмена
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="relative px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center min-w-[120px]"
                    >
                      {isSubmitting ? (
                        <>
                          <Loader2 className="w-5 h-5 animate-spin mr-2" />
                          <span>Отправка...</span>
                        </>
                      ) : (
                        'Отправить отзыв'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showSuccessModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-[70] flex items-center justify-center p-4 bg-black/80"
          >
            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              className="bg-white rounded-2xl p-8 max-w-md w-full text-center relative overflow-hidden"
            >
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 2 }}
                transition={{ duration: 0.5 }}
                className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-pink-500/10 rounded-full"
                style={{ transformOrigin: 'center center' }}
              />

              <motion.div
                initial={{ scale: 0, rotate: -180 }}
                animate={{ scale: 1, rotate: 0 }}
                transition={{ type: "spring", duration: 0.7 }}
                className="w-20 h-20 bg-gradient-to-r from-purple-600 to-pink-600 rounded-full mx-auto mb-6 flex items-center justify-center"
              >
                <svg
                  className="w-10 h-10 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <motion.path
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </motion.div>

              <motion.h3
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
                className="text-2xl font-bold text-gray-900 mb-4"
              >
                Спасибо за ваш отзыв!
              </motion.h3>

              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
                className="text-gray-600 mb-4"
              >
                Ваш отзыв успешно отправлен и будет опубликован после проверки модератором.
              </motion.p>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
                className="text-sm text-gray-500"
              >
                Окно закроется через {countdown} {
                  countdown === 1 ? 'секунду' :
                  countdown > 1 && countdown < 5 ? 'секунды' :
                  'секунд'
                }
              </motion.div>

              {/* Progress bar */}
              <motion.div 
                className="mt-4 h-1 bg-gray-200 rounded-full overflow-hidden"
              >
                <motion.div
                  initial={{ width: '100%' }}
                  animate={{ width: '0%' }}
                  transition={{ duration: 5, ease: 'linear' }}
                  className="h-full bg-gradient-to-r from-purple-600 to-pink-600"
                />
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
