import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Download, Share2,Share, X } from 'lucide-react';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

export function InstallPWA() {
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
  const [showInstallButton, setShowInstallButton] = useState(false);
  const [showIOSPrompt, setShowIOSPrompt] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false);

  useEffect(() => {
    // Check if app is installed
    const checkInstalled = () => {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setIsInstalled(true);
        return true;
      }
      return false;
    };

    // Check if it's iOS
    const isIOS = () => {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    };

    // Show iOS prompt if:
    // 1. It's iOS device
    // 2. App is not installed
    // 3. User hasn't dismissed the prompt before
    const checkIOSInstall = () => {
      if (isIOS() && !checkInstalled() && !localStorage.getItem('iosPromptDismissed')) {
        // Wait a bit before showing the prompt
        setTimeout(() => {
          setShowIOSPrompt(true);
        }, 2000);
      }
    };

    checkIOSInstall();

    const handler = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e as BeforeInstallPromptEvent);
      setShowInstallButton(true);
    };

    window.addEventListener('beforeinstallprompt', handler);
    window.addEventListener('appinstalled', () => {
      setIsInstalled(true);
      setShowInstallButton(false);
      setShowIOSPrompt(false);
      setDeferredPrompt(null);
    });

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);

  const handleInstall = async () => {
    if (!deferredPrompt) return;

    try {
      await deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      
      if (outcome === 'accepted') {
        setIsInstalled(true);
      }
    } catch (error) {
      console.error('Error installing PWA:', error);
    } finally {
      setDeferredPrompt(null);
      setShowInstallButton(false);
    }
  };

  const dismissIOSPrompt = () => {
    setShowIOSPrompt(false);
    localStorage.setItem('iosPromptDismissed', 'true');
  };

  if (isInstalled) return null;

  return (
    <>
      {/* Android Install Button */}
      {showInstallButton && (
        <motion.button
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          onClick={handleInstall}
          className="fixed bottom-24 right-6 bg-gradient-to-r from-amber-500 to-amber-600 text-white p-4 rounded-full shadow-lg z-40 hover:shadow-xl transition-shadow"
        >
          <Download className="w-6 h-6" />
        </motion.button>
      )}

      {/* iOS Install Prompt */}
      <AnimatePresence>
        {showIOSPrompt && (
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 100 }}
            className="fixed bottom-0 inset-x-0 p-4 bg-white shadow-lg z-50"
          >
            <div className="max-w-xl mx-auto bg-gradient-to-r from-amber-50 to-amber-100 rounded-2xl p-4 relative">
              <button
                onClick={dismissIOSPrompt}
                className="absolute top-2 right-2 p-2 text-gray-500 hover:text-gray-700"
              >
                <X className="w-5 h-5" />
              </button>

              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <Share2 className="w-6 h-6 text-amber-600" />
                </div>
                <div className="flex-1">
                  <h3 className="text-lg font-semibold text-gray-900 mb-1">
                    Установите приложение
                  </h3>
                  <p className="text-gray-600 text-sm mb-4">
                    Установите наше приложение на ваш iPhone для быстрого доступа и удобной работы в офлайн режиме.
                  </p>
                  <div className="space-y-2 text-sm text-gray-600">
                    <p>1. Нажмите внизу браузера на кнопку «Поделиться» <Share className="w-4 h-4 inline" /></p>
                    <p>2. Прокрутите вниз и выберите «На экран «Домой»»</p>
                    <p>3. Нажмите «Добавить» в верхнем правом углу</p>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
